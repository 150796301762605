import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { Endpoints } from "../Service-Endpoints/endpoints";
import { Utils } from "../Utils/utils";

@Injectable({
  providedIn: "root",
})
export class DataLayerService {
  constructor(private http: HttpClient) {}

  getStates(): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.stateList}?country_id=225`
    );
  }

  getPricingList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.pricing}?${Utils.addFilter(
        filters
      )}`
    );
  }

  getBillList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}v1/bill-types?${Utils.addFilter(filters)}`
    );
  }

  getTowYardList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.towyards}?${Utils.addFilter(
        filters
      )}`
    );
  }

  getPoliceDeptList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}${
        Endpoints.Names.policeDepartment
      }?${Utils.addFilter(filters)}`
    );
  }

  getUserList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.users}?${Utils.addFilter(
        filters
      )}`
    );
  }

  getViolationsList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.violations}?${Utils.addFilter(
        filters
      )}`
    );
  }

  getAccountTypeList(): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.accountTypes}`
    );
  }

  getPropertyList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}v1/properties?${Utils.addFilter(filters)}`
    );
  }

  getCompanyList(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}v1/companies?${Utils.addFilter(filters)}`
    );
  }

  getPropertyDetail(id: number): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.properties}/${id}`
    );
  }

  getVehicleClass(): Observable<any> {
    return this.http.get(
      `${environment.base_url}${Endpoints.Names.pricingTypes}`
    );
  }

  getTrucks(): Observable<any> {
    return this.http.get(`${environment.base_url}${Endpoints.Names.trucks}`);
  }

  getColors(): Observable<any> {
    return this.http.get(`${environment.base_url}${Endpoints.Names.colors}`);
  }

  getChargesType(filters: any): Observable<any> {
    return this.http.get(
      `${environment.base_url}${
        Endpoints.Names.pricingCharges
      }?${Utils.addFilter(filters)}`
    );
  }
}
