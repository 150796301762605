import { Observable, throwError } from "rxjs";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
export const InterceptorSkipHeader = "X-Skip-Interceptor";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { Redirection } from "../Redirection/routes";

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  urls: any = Redirection.url;

  constructor(private router: Router) {}
  authHeader: any;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.setUser();

    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    if (this.authHeader === null) {
      localStorage.clear();
      this.router.navigate([this.urls.signin]);
      return next.handle(req.clone());
    }
    const clonedReq = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + this.authHeader),
    });
    return next.handle(clonedReq).pipe(
      catchError((error) => {
        if (error.status === 401) {
          localStorage.clear();
          this.router.navigate([this.urls.signin]);
        }
        return throwError(error);
      })
    );
  }

  setUser() {
    this.authHeader = JSON.parse(localStorage.getItem("token"));
  }
}
