import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { LocalStoreService } from "./local-store.service";
import { LoggedINUser } from "../models/logged-user";
import { Endpoints } from "../Service-Endpoints/endpoints";
import { Redirection } from "../Redirection/routes";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public userSubject: BehaviorSubject<LoggedINUser>;
  public user: Observable<LoggedINUser>;

  constructor(
    private store: LocalStoreService,
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<LoggedINUser>(
      this.store.getItem("user")
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  public isLoggedIn() {
    return this.userSubject.value ? true : false;
  }

  signin(credentials: any) {
    return this.http
      .post<LoggedINUser>(
        `${environment.base_url}${Endpoints.Names.organizationLogin}`,
        credentials
      )
      .pipe(
        map((user) => {
          if (user.data) {
            localStorage.removeItem('rememberMeUser');
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.store.setItem("token", user.data.token ?? "{}");
            this.store.setItem("user", user);
            this.store.setItem("isLoggedin", true);             
            this.remember(credentials);
            this.userSubject.next(user);
          }
          return user;
        })
      );
  }

  remember(cred) {
    if(cred.rememberMe === true){
      const payload =
       {
        data: { user_name: cred.user_name,
          access_code: cred.access_code,
          password: cred.password,
          rememberMe: cred.rememberMe
        }
      }
      this.store.setItem("rememberMeUser", payload);
    }
  }

  signout() {
    return this.http
      .delete(`${environment.base_url}${Endpoints.Names.organizationLogout}`)
      .toPromise()
      .then(() => {
        this.store.setItem("isLoggedin", false);

        this.store.clear();
        this.userSubject.next(null);
        window.location.reload();

        setTimeout(function () {
          this.router.navigate([Redirection.url.signin]);
        }, 500);
      });
  }
}
