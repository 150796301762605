import { ProfileModule } from './views/pages/profile/profile.module';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseComponent } from "./views/layout/base/base.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";
import { Roles } from './shared/models/_roles';
import { RoleGuardService } from './core/guard/roles.guard';

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "users",
        data: { roles: [Roles.organization_admin, Roles.dispatcher] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import("./views/pages/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: "roles",
        data: { roles: [Roles.organization_admin, Roles.dispatcher] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import("./views/pages/role-management/role-management.module").then(
            (m) => m.RoleManagementModule
          ),
      },
      {
        path: "permissions",
        data: { roles: [Roles.organization_admin, Roles.dispatcher] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/permission-management/permission-management.module"
          ).then((m) => m.PermissionManagementModule),
      },
      {
        path: "violations",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/violations-management/violations-management.module"
          ).then((m) => m.ViolationsManagementModule),
      },
      {
        path: "properties",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/property-management/property-management.module"
          ).then((m) => m.PropertyManagementModule),
      },
      {
        path: "profile",
        loadChildren: () =>
          import(
            "./views/pages/profile/profile.module"
          ).then((m) => m.ProfileModule),
      },
      {
        path: "trucks",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/trucks-management/trucks-management.module"
          ).then((m) => m.TrucksManagementModule),
      },
      {
        path: "towyards",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/towyard-management/towyard-management.module"
          ).then((m) => m.TowyardManagementModule),
      },
      {
        path: "pricing",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/pricing-management/pricing-management.module"
          ).then((m) => m.PricingManagementModule),
      },
      {
        path: "police",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales, Roles.driver] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/police-management/police-management.module"
          ).then((m) => m.PoliceManagementModule),
      },
      {
        path: "company",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales, Roles.driver] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import(
            "./views/pages/company-management/company-management.module"
          ).then((m) => m.CompanyManagementModule),
      },
      {
        path: "tow-tickets/ppi",
        data: { roles: [Roles.organization_admin, Roles.dispatcher, Roles.sales, Roles.driver] },
        canActivate: [RoleGuardService],
        loadChildren: () =>
          import("./views/pages/ppi-management/ppi-management.module").then(
            (m) => m.PpiManagementModule
          ),
      },
      {
        path: "apps",
        loadChildren: () =>
          import("./views/pages/apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "ui-components",
        loadChildren: () =>
          import("./views/pages/ui-components/ui-components.module").then(
            (m) => m.UiComponentsModule
          ),
      },
      {
        path: "advanced-ui",
        loadChildren: () =>
          import("./views/pages/advanced-ui/advanced-ui.module").then(
            (m) => m.AdvancedUiModule
          ),
      },
      {
        path: "form-elements",
        loadChildren: () =>
          import("./views/pages/form-elements/form-elements.module").then(
            (m) => m.FormElementsModule
          ),
      },
      {
        path: "advanced-form-elements",
        loadChildren: () =>
          import(
            "./views/pages/advanced-form-elements/advanced-form-elements.module"
          ).then((m) => m.AdvancedFormElementsModule),
      },
      {
        path: "charts-graphs",
        loadChildren: () =>
          import("./views/pages/charts-graphs/charts-graphs.module").then(
            (m) => m.ChartsGraphsModule
          ),
      },
      {
        path: "tables",
        loadChildren: () =>
          import("./views/pages/tables/tables.module").then(
            (m) => m.TablesModule
          ),
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./views/pages/icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "general",
        loadChildren: () =>
          import("./views/pages/general/general.module").then(
            (m) => m.GeneralModule
          ),
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  {
    path: "error",
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: "Page Not Found",
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: "error/:type",
    component: ErrorPageComponent,
  },
  { path: "**", redirectTo: "error", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
