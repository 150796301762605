export class Redirection {
  static url = {
    signin: "/auth/login",
    profile: "/profile",

    userAdd: "/users/add",
    userList: "/users/list",
    userEdit: "/users/edit",

    truckList: "/trucks/list",
    truckAdd: "/trucks/add",
    truckEdit: "/trucks/edit",

    towyardList: "/towyards/list",
    towyardAdd: "/towyards/add",
    towyardEdit: "/towyards/edit",
    violationList: "/violations/list",

    companyList: "/company/list",
    companyAdd: "/company/add",
    companyEdit: "/company/edit",

    policeList: "/police/list",
    policeAdd: "/police/add",
    policeEdit: "/police/edit",

    propertyList: "/properties/list",
    propertyAdd: "/properties/add",
    propertyEdit: "/properties/edit",

    pricingList: "/pricing/list",
    pricingAdd: "/pricing/add",
    pricingEdit: "/pricing/edit",

    roleAdd: "/roles/add",
    roleEdit: "/roles/edit",
    roleList: "/roles/list",

    permissionAdd: "/permissions/add",
    permissionEdit: "/permissions/edit",
    permissionsList: "/permissions/list",

    dashboard: "/dashboard",

    ppiList: "/tow-tickets/ppi/list",
    ppiAdd: "/tow-tickets/ppi/add",
    ppiEdit: "/tow-tickets/ppi/edit",
  };
}
