<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <form class="search-form">
      <div class="input-group">
        <div class="input-group-text">
          <i class="feather icon-search"></i>
        </div>
        <input type="text" class="form-control" id="navbarForm" placeholder="Search here...">
      </div>
    </form>
    <ul class="navbar-nav">
      <!-- <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="languageDropdown" role="button">
          <img src="assets/images/flags/us.svg" class="wd-20 me-1" title="us" alt="us">
          <span class="fw-bold ms-1 me-1 d-none d-md-inline-block">English</span>
        </a>
        <div ngbDropdownMenu aria-labelledby="languageDropdown">
          <a ngbDropdownItem class="py-2"> <img src="assets/images/flags/us.svg" class="wd-20 me-1" title="us" alt="us"> <span class="ms-1"> English </span></a>
          <a ngbDropdownItem class="py-2"> <img src="assets/images/flags/fr.svg" class="wd-20 me-1" title="fr" alt="fr"> <span class="ms-1"> French </span></a>
          <a ngbDropdownItem class="py-2"> <img src="assets/images/flags/de.svg" class="wd-20 me-1" title="de" alt="de"> <span class="ms-1"> German </span></a>
          <a ngbDropdownItem class="py-2"> <img src="assets/images/flags/pt.svg" class="wd-20 me-1" title="pt" alt="pt"> <span class="ms-1"> Portuguese </span></a>
          <a ngbDropdownItem class="py-2"> <img src="assets/images/flags/es.svg" class="wd-20 me-1" title="es" alt="es"> <span class="ms-1"> Spanish </span></a>
        </div>
      </li> -->
      <li class="nav-item nav-apps" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="appsDropdown">
          <i class="link-icon feather icon-grid"></i>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="appsDropdown">
          <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
            <p class="mb-0 fw-bold">Features</p>
            <!-- <a href="javascript:;" class="text-muted">Edit</a> -->
          </div>
          <div class="row g-0 p-1">
            <div class="col-3 text-center">
              <a [routerLink]='userList'
                class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                  class="feather icon-users icon-lg mb-1"></i>
                <p class="tx-12">Users</p>
              </a>
            </div>
            <div class="col-3 text-center">
              <a [routerLink]='propertyList'
                class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                  class="feather icon-home icon-lg mb-1"></i>
                <p class="tx-12">Properties</p>
              </a>
            </div>
            <div class="col-3 text-center">
              <a [routerLink]='truckList'
                class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                  class="feather icon-truck icon-lg mb-1"></i>
                <p class="tx-12">Trucks</p>
              </a>
            </div>
            <div class="col-3 text-center">
              <a [routerLink]='pricingList'
                class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                  class="feather icon-dollar-sign icon-lg mb-1"></i>
                <p class="tx-12">Pricing</p>
              </a>
            </div>
          </div>
          <!-- <div class="px-3 py-2 d-flex align-items-center justify-content-center border-top">
            <a href="" (click)="false">View all</a>
          </div> -->
        </div>
      </li>
      <!-- <li class="nav-item nav-messages" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="messageDropdown">
          <i class="link-icon feather icon-mail"></i>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="messageDropdown">
          <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
            <p>9 New Messages</p>
            <a href="" (click)="false" class="text-muted">Clear all</a>
          </div>
          <div class="p-1">
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="me-3">
                <img class="wd-30 ht-30 rounded-circle" src="https://via.placeholder.com/30x30" alt="user">
              </div>
              <div class="d-flex justify-content-between flex-grow-1">
                <div class="me-4">
                  <p>Leonardo Payne</p>
                  <p class="tx-12 text-muted">Project status</p>
                </div>
                <p class="tx-12 text-muted">2 min ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="me-3">
                <img class="wd-30 ht-30 rounded-circle" src="https://via.placeholder.com/30x30" alt="user">
              </div>
              <div class="d-flex justify-content-between flex-grow-1">
                <div class="me-4">
                  <p>Carl Henson</p>
                  <p class="tx-12 text-muted">Client meeting</p>
                </div>
                <p class="tx-12 text-muted">30 min ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="me-3">
                <img class="wd-30 ht-30 rounded-circle" src="https://via.placeholder.com/30x30" alt="user">
              </div>
              <div class="d-flex justify-content-between flex-grow-1">
                <div class="me-4">
                  <p>Jensen Combs</p>
                  <p class="tx-12 text-muted">Project updates</p>
                </div>
                <p class="tx-12 text-muted">1 hrs ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="me-3">
                <img class="wd-30 ht-30 rounded-circle" src="https://via.placeholder.com/30x30" alt="user">
              </div>
              <div class="d-flex justify-content-between flex-grow-1">
                <div class="me-4">
                  <p>Amiah Burton</p>
                  <p class="tx-12 text-muted">Project deatline</p>
                </div>
                <p class="tx-12 text-muted">2 hrs ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="me-3">
                <img class="wd-30 ht-30 rounded-circle" src="https://via.placeholder.com/30x30" alt="user">
              </div>
              <div class="d-flex justify-content-between flex-grow-1">
                <div class="me-4">
                  <p>Yaretzi Mayo</p>
                  <p class="tx-12 text-muted">New record</p>
                </div>
                <p class="tx-12 text-muted">5 hrs ago</p>
              </div>	
            </a>
          </div>
          <div class="px-3 py-2 d-flex align-items-center justify-content-center border-top">
            <a href="" (click)="false">View all</a>
          </div>
        </div>
      </li>
      <li class="nav-item nav-notifications" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="notificationDropdown">
          <i class="link-icon feather icon-bell"></i>
          <div class="indicator">
            <div class="circle"></div>
          </div>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="notificationDropdown">
          <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
            <p>6 New Notifications</p>
            <a href="" (click)="false" class="text-muted">Clear all</a>
          </div>
          <div class="p-1">
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                <i class="feather icon-gift icon-sm text-white"></i>
              </div>
              <div class="flex-grow-1 me-2">
                <p>New Order Recieved</p>
                <p class="tx-12 text-muted">30 min ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                <i class="feather icon-alert-circle icon-sm text-white"></i>
              </div>
              <div class="flex-grow-1 me-2">
                <p>Server Limit Reached!</p>
                <p class="tx-12 text-muted">1 hrs ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                <img class="wd-30 ht-30 rounded-circle" src="https://via.placeholder.com/30x30" alt="userr">
              </div>
              <div class="flex-grow-1 me-2">
                <p>New customer registered</p>
                <p class="tx-12 text-muted">2 sec ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                <i class="feather icon-layers icon-sm text-white"></i>
              </div>
              <div class="flex-grow-1 me-2">
                <p>Apps are ready for update</p>
                <p class="tx-12 text-muted">5 hrs ago</p>
              </div>	
            </a>
            <a href="" (click)="false" class="dropdown-item d-flex align-items-center py-2">
              <div class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                <i class="feather icon-download icon-sm text-white"></i>
              </div>
              <div class="flex-grow-1 me-2">
                <p>Download completed</p>
                <p class="tx-12 text-muted">6 hrs ago</p>
              </div>	
            </a>
          </div>
          <div class="px-3 py-2 d-flex align-items-center justify-content-center border-top">
            <a href="" (click)="false">View all</a>
          </div>
        </div>
      </li> -->
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img class="wd-30 ht-30 rounded-circle" src="./../../../../assets/images/others/user18.svg" alt="profile">
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
          <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
            <div class="mb-3">
              <img class="wd-80 ht-80 rounded-circle" src="./../../../../assets/images/others/user40.svg" alt="">
            </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder textUpper">{{user?.data?.name}}</p>
              <p class="tx-12 text-muted textLower">{{user?.data?.email}}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <li class="dropdown-item py-2">
              <a [routerLink]="profileUrl" class="d-flex text-body ms-0">
                <i class="feather icon-user me-2 icon-md"></i>
                <span>Profile</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a href="" (click)="false" class="d-flex text-body ms-0">
                <i class="feather icon-edit me-2 icon-md"></i>
                <span>Edit Profile</span>
              </a>
            </li>
            <!-- <li class="dropdown-item py-2">
              <a href="" (click)="false" class="d-flex text-body ms-0">
                <i class="feather icon-repeat me-2 icon-md"></i>
                <span>Switch User</span>
              </a>
            </li> -->
            <li class="dropdown-item py-2">
              <a (click)="logoutAlert()" class="d-flex text-body ms-0">
                <i class="feather icon-log-out me-2 icon-md"></i>
                <span>Log Out</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>