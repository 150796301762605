import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  Renderer2,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { Redirection } from "src/app/shared/Redirection/routes";
import { LoggedINUser } from "src/app/shared/models/logged-user";
import { AuthService } from "src/app/shared/services/auth.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  userList = Redirection.url.userList;
  profileUrl = Redirection.url.profile;
  propertyList = Redirection.url.propertyList;
  truckList = Redirection.url.truckList;
  pricingList = Redirection.url.pricingList;
  user: LoggedINUser;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.user = this.auth.userValue;
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle("sidebar-open");
  }

  /**
   * Logout
   */

  logoutAlert() {
    Swal.fire({
      title: "Logout",
      text: "Are you sure that you want to logout this session?",
      confirmButtonColor: "#6571ff",
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowEscapeKey: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.onLogout();
      }
    });
  }

  onLogout() {
    this.auth.signout();
  }
}
