import { Redirection } from "src/app/shared/Redirection/routes";
import { MenuItem } from "./menu.model";

export const Driver: MenuItem[] = [
  {
    label: "Main",
    isTitle: true,
  },
  {
    label: "Dashboard",
    icon: "home",
    link: Redirection.url.dashboard,
  },
  {
    label: "Ticket Management",
    isTitle: true,
  },
  {
    label: "Tow Tickets",
    icon: "user",
    subItems: [
      {
        label: "PPI",
        link: Redirection.url.ppiList,
      },
    ],
  },
  {
    label: "Other",
    isTitle: true,
  },
  {
    label: "Other Sections",
    icon: "server",
    subItems: [
      {
        label: "Police Departments",
        link: Redirection.url.policeList,
      },
      {
        label: "Companies",
        link: Redirection.url.companyList,
      },
    ],
  },
]