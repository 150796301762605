import { Injectable } from "@angular/core";
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Router } from "@angular/router";
import { Redirection } from "src/app/shared/Redirection/routes";
import { LoggedINUser } from "src/app/shared/models/logged-user";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  user: LoggedINUser;

  constructor(private router: Router, private auth: AuthService) {}

  canActivate() {
    this.user = this.auth.userValue;
    if (this.user) {
      return true;
    } else {
      this.router.navigateByUrl(Redirection.url.signin);
      return false;
    }
  }
}
