import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";
import { Redirection } from "../../shared/Redirection/routes";

@Injectable({ providedIn: "root" })
export class RoleGuardService {
  constructor(
    private authenticationService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userRole = this.authenticationService.userValue?.data?.role;
    if (route.data["roles"].some((data: any) => userRole.toString() === data.toString())) {
      return true;
    } else {
      this.router.navigate([Redirection.url.dashboard]);
      return false;
    }
  }
}