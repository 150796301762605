import { FormGroup } from "@angular/forms";

export const constant = {
  user: {
    userType: "SubAdmin",
  },
  regEx: {
    phoneMask: [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    alphabetOnlyRegEx: /^[A-Za-z -]+$/,
    linkValidation:
      "/^(http[s]?://){0,1}(www.){0,1}[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}[.]{0,1}/",
    numberOnlyRegEx: /[^0-9]*/,
    emailRegEx: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
    passwordRegEx:
      "^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z!@#$%^&*()_+-?><:~]{6,}$",
    usPhoneRegEx: /^.{10,}$/,
    zipRegEx: "^[0-9]{5}([- /]?[0-9]{4})?$",
    ssnRegEx: /^\d{3}-?\d{2}-?\d{4}$/,
    einRegEx: /^\d{3}-?\d{2}-?\d{4}$/,
    youTubeLinkRegEx:
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  },
  ToastConfig: {
    progressBar: true,
    closeButton: true,
    positionClass: "toast-bottom-right",
    preventDuplicates: true,
  },
  searchSpecific: {
    componentRestrictions: {
      country: ["USA"],
    },
  },

  api: {
    key: "AIzaSyAJnDvfb4o372s6og8i70DPNAcD9FpnAT8",
  },
  function: {
    numberOnly: (event: any) => {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    },
    validateNumber: (event: any) => {},
    alphabetOnly: (event: any) => {
      const charCode = event.which ? event.which : event.keyCode; // 32 is ASCII code of space bar
      if (
        charCode > 31 &&
        charCode > 32 &&
        (charCode < 65 || charCode > 90) &&
        (charCode < 97 || charCode > 122)
      ) {
        return false;
      }
      return true;
    },
    isNumberOnly: (event: any) => {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    },
    addCommasToPhone: (mobileNumber: any) => {
      let m = "(";
      for (let i = 0; i < 10; i++) {
        if (i === 3) {
          m = m + ") ";
        }
        if (i === 6) {
          m = m + "-" + mobileNumber[i];
        } else {
          m = m + mobileNumber[i];
        }
      }
      return m;
    },
    removeCommasFromPhone: (mobileNumber: any) => {
      return mobileNumber.replace(/\D/g, "");
    },

    modifyEnterToTab: (event: any) => {
      if (event.keyCode === 13) {
        // tslint:disable-next-line: no-unused-expression
        event.keyCode === 9;
      }
    },

    keyDownFunction: (event: any) => {
      if (event.keyCode === 13) {
        const inputs = Array.prototype.slice.call(
          document.querySelectorAll("input")
        );
        const index =
          (inputs.indexOf(document.activeElement) + 1) % inputs.length;
        const input = inputs[index];
        input.focus();
        input.select();
      }
    },

    convertSpaceToComma: (event: any) => {
      return event.split(/[ ,]+/).join("-");
    },

    transformTimestamp(value: any): any {
      if (value) {
        if (value.toString().indexOf(" ") === -1) {
          value = value + " 00:00:00";
        }
        const temp = value.toString().replace(" ", "T");
        return new Date(temp);
      } else {
        return null;
      }
    },
    removeEmptyKey(obj: any) {
      if (obj) {
        Object.keys(obj).forEach((key) => {
          if (obj[key] === "" || obj[key] === undefined) {
            delete obj[key];
          }
        });
        return obj;
      }
    },
    moreThanZero(num: any): any {
      if (!(num.key > 0)) {
        return false;
      }
      return true;
    },
    capitalizeFirstLetter(string: any) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    updateNavUrls(term: string, offerData: any): any {
      if (term) {
        if (offerData.links?.first) {
          offerData.links.first += `&term=${term}`;
        }
        if (offerData.links?.prev) {
          offerData.links.prev += `&term=${term}`;
        }
        if (offerData.links?.next) {
          offerData.links.next += `&term=${term}`;
        }
        if (offerData.links?.last) {
          offerData.links.last += `&term=${term}`;
        }
      }
      return offerData;
    },
    validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        control?.markAsTouched({ onlySelf: true });
        control?.markAsDirty({ onlySelf: true });
      });
    },
    titleCaseWord(word: string) {
      if (!word) return word;
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    },
    removeUnderscore(name: string) {
      if (name) return name.replace(/_/g, " ");
      return name;
    },
  },
};
