import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStoreService {
  private ls = window.localStorage;
  constructor() {}

  public setItem(key: string, value: any) {
    const obj = JSON.stringify(value);
    this.ls.setItem(key, obj);
    return true;
  }

  public getItem(key: string) {
    const value = this.ls.getItem(key) ?? "{}";
    try {
      return JSON.parse(value);
    } catch (e) {
      // console.log(e)
      return null;
    }
  }
  public clear() {
    this.ls.clear();
  }
}
