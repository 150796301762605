export class Endpoints {
  static vers = {
    v1: "v1/",
  };

  static Names = {
    companies: this.vers.v1 + "companies",
    policeDepartment: this.vers.v1 + "police-departments",
    pricingUI: this.vers.v1 + "pricing-ui",
    pricing: this.vers.v1 + "pricing",
    properties: this.vers.v1 + "properties",
    accountTypes: this.vers.v1 + "account-types",
    towyards: this.vers.v1 + "towyards",
    trucks: this.vers.v1 + "trucks",
    truckConditions: this.vers.v1 + "truck-conditions",
    truckTypes: this.vers.v1 + "truck-types",
    stateList: this.vers.v1 + "states",
    truckSizes: this.vers.v1 + "truck-sizes",
    users: this.vers.v1 + "users",
    violations: this.vers.v1 + "violations",
    organizationLogin: this.vers.v1 + "auth/organization/login",
    organizationLogout: this.vers.v1 + "logout",
    roles: this.vers.v1 + "roles",
    permissions: this.vers.v1 + "permissions",
    ppis: this.vers.v1 + "ppis",
    dashboard: this.vers.v1 + "dashboard/counts",
    pricingCharges: this.vers.v1 + "charges",
    pricingTypes: this.vers.v1 + "pricing-types",
    colors: this.vers.v1 + "colors",
    releaseTypes: this.vers.v1 + "release-types",
    cardBrands: this.vers.v1 + "card-brands",
    refundTypes: this.vers.v1 + "refund-types",
  };
}
