import { FormGroup } from "@angular/forms";
import { constant } from "./constant";

export class Utils {
  static isMobile() {
    return window && window.matchMedia("(max-width: 767px)").matches;
  }
  static ngbDateToDate(ngbDate: { month: any; day: any; year: any }) {
    if (!ngbDate) {
      return null;
    }
    return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
  }
  static dateToNgbDate(date: Date) {
    if (!date) {
      return null;
    }
    date = new Date(date);
    return {
      month: date.getMonth() + 1,
      day: date.getDate(),
      year: date.getFullYear(),
    };
  }
  static scrollToTop(selector: string) {
    if (document) {
      const element = <HTMLElement>document.querySelector(selector);
      element.scrollTop = 0;
    }
  }
  static genId() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static convertToDate(object: any) {
    if (object) {
      return object.year + "/" + object.month + "/" + object.day;
    }
    return null;
  }

  static addFilter(query: any) {
    query = constant.function.removeEmptyKey(query);
    let q = "";
    if (query) {
      Object.keys(query).forEach((key) => {
        q += `${key}=${query[key]}&`;
      });
    }
    return q.slice(0, -1);
  }

  static removeUnderscore(list) {
    var result = list
      .map(function (e: any) {
        return e.name.replace("_", " ");
      })
      .join(", ");
    return result;
  }

  static arraytoJsonString(list) {
    if (list.length > 0) {
      const arrayList = [];
      list.forEach((element: any) => {
        if (element.id) {
          const payload = {
            id: element.id.id,
            price: element.price,
            quantity: element.quantity,
            description: element.description,
          };
          arrayList.push(payload);
        }
      });
      return arrayList;
    }
    return null;
  }

  static arraytoAdjustmentString(list) {
    if (list.length > 0) {
      const arrayList = [];
      list.forEach((element: any) => {
        if (element.id) {
          const payload = {
            id: element.id.id,
            price: element.price,
            description: element.description,
          };
          arrayList.push(payload);
        }
      });
      return arrayList;
    }
    return null;
  }

  static createFormData(formData: FormData, key: any, data: any) {
    if (data === Object(data) || Array.isArray(data)) {
      for (var i in data) {
        this.createFormData(formData, key + "[" + i + "]", data[i]);
      }
    } else {
      formData.append(key, data);
    }
  }

  static isNotEmptyArray(list: []): boolean {
    const array = list.filter((s: any) => s.id != null);
    return array.length > 0 ? true : false;
  }

  static removeEmptyArray(list: []) {
    const arrayList = list.filter((s: any) => s.id != null);
    return arrayList;
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    });
  }
  static updateNavUrls(term: string, offerData: any): any {
    if (term) {
      if (offerData.links?.first) {
        offerData.links.first += `&term=${term}`;
      }
      if (offerData.links?.prev) {
        offerData.links.prev += `&term=${term}`;
      }
      if (offerData.links?.next) {
        offerData.links.next += `&term=${term}`;
      }
      if (offerData.links?.last) {
        offerData.links.last += `&term=${term}`;
      }
    }
    return offerData;
  }
  static titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
}
